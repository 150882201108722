const React = require('react');

const loadable = require('@loadable/component').default;
const { isServer } = require('@ulta/core/utils/device_detection/device_detection')
const { LayerHost } = require('@ulta/core/components/LayerHost/LayerHost');
const { RenderOnViewportEntry } = require('@ulta/core/components/RenderOnViewportEntry/RenderOnViewportEntry');

// DSOTF-98980 - top used components
const { PromotedCardMini } = require('@ulta/modules/PromotedCardMini/PromotedCardMini');
const { LargeDeal } = require('@ulta/modules/LargeDealRail/LargeDealRail');
const { FooterNavLinkGroup } = require('@ulta/modules/FooterNavLinkGroup/FooterNavLinkGroup');
const { Section } = require('@ulta/modules/Section/Section');
const { CreativeGraphiElement } = require('@ulta/modules/CreativeGraphicElement/CreativeGraphicElement');
const { Image } = require('@ulta/modules/Image/Image');
const { AddressForm } = require('@ulta/modules/AddressForm/AddressForm');
const { CompactCard } = require('@ulta/modules/CompactCard/CompactCard');
const { CuratedProductRail } = require('@ulta/modules/CuratedProductRail/CuratedProductRail');
const { ColumnContainer } = require('@ulta/modules/ColumnContainer/ColumnContainer');
const { VerticalSlot } = require('@ulta/modules/VerticalSlot/VerticalSlot');
const { Breadcrumbs } = require('@ulta/modules/Breadcrumbs/Breadcrumbs');
const { CriteoProductRail } = require('@ulta/modules/CriteoProductRail/CriteoProductRail');
const { NavigationLinksGroup } = require('@ulta/modules/NavigationLinksGroup/NavigationLinksGroup');
const { NavigationLinksSubGroup } = require('@ulta/modules/NavigationLinksSubGroup/NavigationLinksSubGroup');
const { NavigationOverlayFeaturedContent } = require('@ulta/modules/NavigationOverlayFeaturedContent/NavigationOverlayFeaturedContent');
const { Sdk } = require('@ulta/modules/Sdk/Sdk');
const { UltamateRewardsOffers } = require('@ulta/modules/UltamateRewardsOffers/UltamateRewardsOffers');

// DSOTF2-114516 - Subset of Components shared between HOME, PLP, PDP
const { AppDetails } = require('@ulta/modules/AppDetails/AppDetails');
const { BagIcon } = require('@ulta/modules/BagIcon/BagIcon');
const { DataCaptureEvent } = require('@ulta/modules/DataCaptureEvent/DataCaptureEvent');
const { FavoritesUtilityLink } = require('@ulta/modules/FavoritesUtilityLink/FavoritesUtilityLink');
const { GridContainer } = require('@ulta/modules/GridContainer/GridContainer');
const { ImageNavigation } = require('@ulta/modules/ImageNavigation/ImageNavigation');
const { JSscriptTag } = require('@ulta/modules/JSscriptTag/JSscriptTag');
const { MainWrapper } = require('@ulta/modules/MainWrapper/MainWrapper');
const { Page } = require('@ulta/modules/Page/Page');
const { PrimaryBar } = require('@ulta/modules/PrimaryBar/PrimaryBar');
const { PrimaryLinks } = require('@ulta/modules/PrimaryLinks/PrimaryLinks');
const { SearchHeader } = require('@ulta/modules/SearchHeader/SearchHeader');
const { Spacer } = require('@ulta/modules/Spacer/Spacer');
const { StateWrapper } = require('@ulta/modules/StateWrapper/StateWrapper');
const { WebPageMetaData } = require('@ulta/modules/WebpageMetaData/WebpageMetaData');
const { useAppConfigContext } = require('@ulta/core/providers/AppConfigProvider/AppConfigProvider');

const LoadableComponent = props => {
  const [CacheComponent, setCacheComponent] = React.useState();
  const [moduleName, setModuleName] = React.useState();
  const [useCacheComponent, setUseCacheComponent] = React.useState( false );
  const { isBot } = useAppConfigContext();
  
  React.useEffect( () => {
    if( props?.moduleName !== moduleName ){
      setModuleName( props.moduleName );
      setCacheComponent( loadable( () => import(
        /* webpackChunkName: "[request]" */
        /* webpackInclude: /\.js$/ */
        /* webpackExclude: /.*(test|stories)\.js$/ */
        `@ulta/modules/${props.moduleName}/${props.moduleName}.js`
      ) ) );
      setUseCacheComponent( true );
    }
  }, [props.moduleName] );

  const Component = loadable( () => import(
    /* webpackChunkName: "[request]" */
    /* webpackInclude: /\.js$/ */
    /* webpackExclude: /.*(test|stories)\.js$/ */
    `@ulta/modules/${props.moduleName}/${props.moduleName}.js`
  ) );

  if ( !isServer() && !isBot && props.allowDefer) {
    return (
      <RenderOnViewportEntry
        threshold={0}
      >
        <LayerHost
          Content={ useCacheComponent ? CacheComponent : Component }
          { ...props }
        />
      </RenderOnViewportEntry>
    );
  } else {
    return (
      <LayerHost
        Content={ useCacheComponent ? CacheComponent : Component }
        { ...props }
      />
    );
  }

};

module.exports = LoadableComponent;