/**
 * FavoritesUtilityLink for Topbar across the portal
 *
 * @module views/components/FavoritesUtilityLink
 * @memberof -Common
 */
import './FavoritesUtilityLink.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';

import { useTopBar } from '../TopBar/TopBar';

/**
  * Represents a FavoritesUtilityLink component
  *
  * @method
  * @param {FavoritesUtilityLinkProps} props - React properties passed from composition
  * @returns FavoritesUtilityLink
  */
export const FavoritesUtilityLink = React.forwardRef( ( props, _ ) => {
  const { icon, favoritesAction, description } = props;
  const { displayUserNavigation } = useTopBar();

  if( !favoritesAction || !favoritesAction.label || !displayUserNavigation ){
    return null;
  }

  const isButton = favoritesAction.graphql;

  return (
    <div className='FavoritesUtilityLink'>
      { isButton &&
      <Button 
        className='FavoritesUtilityLink__favoriteBtn'
        icon
        clear
        ariaLabel={ description }
        ariaHiddenIcon={ true }
        iconImage={ icon }
        iconSize='xl'
        action={ favoritesAction }
        crossButtonVisibility={ true }
      />
      }
      { !isButton &&
      <Link_Huge
        secondary
        action={ favoritesAction }
        iconImage={ icon }
        iconsize='l'
        ariaLabel={ description }
      />
      }
    </div>
  );
} );

/**
  * Property type definitions
  * @typedef FavoritesUtilityLinkProps
  * @type {object}
  * @property {string} icon - sets the icon
  * @property {object} favoritesAction - sets the action
  * @property {string} description - content for aria label on favorites icon
  */
export const propTypes = {
  icon: PropTypes.string.isRequired,
  favoritesAction: PropTypes.object,
  description: PropTypes.string
};

FavoritesUtilityLink.propTypes = propTypes;
FavoritesUtilityLink.displayName = 'FavoritesUtilityLink';

export default FavoritesUtilityLink;

