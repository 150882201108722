/**
 * Pricing logic for listPrice salePrice and kitPrice
 *
 * @module views/components/ProductPricing
 * @memberof -Common
 */
import './ProductPricing.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

import interpolateString from '@ulta/utils/interpolateString/interpolateString';

/**
  * Represents a ProductPricing component
  *
  * @method
  * @param {ProductPricingProps} props - React properties passed from composition
  * @returns Price
  */
export const ProductPricing = function( props ){
  const {
    listPrice,
    originalPriceLabel,
    listPriceAccessibilityLabel,
    salePrice,
    salePriceAccessibilityLabel,
    kitPrice,
    id,
    compact,
    message,
    appDownloadLabel,
    appDownloadAction
  } = props;

  const { inflection } = useDeviceInflection();

  /**
   * @method setOriginalPrice
   * @summary This method will build the original price string
   * @param {string} data.tokenizedString - originalPriceLabel value tokenized string
   * @param {string} data.listPrice - listPrice value
   */
  const setOriginalPrice = ( data ) => {
    const { tokenizedString, listPrice } = data || {};
    if( !listPrice ){
      return null;
    }
    if( !tokenizedString || tokenizedString && !tokenizedString.includes( '%' ) ){
      return (
        <>
          <Text htmlTag='span'
            textStyle='body-3'
            color='neutral-600'
            textDecoration='line-through'
            ariaHidden={ true }
          >
            { listPrice }
          </Text>
        </>
      );
    }
    const tokenStringLength = tokenizedString.length;
    const tokenIndex = tokenizedString.indexOf( '%' );
    let originalText = '';
    if( tokenIndex > 0 ){
      originalText = tokenizedString.substring( 0, tokenIndex );
      return (
        <>
          <span className='ProductPricing__originalPrice'>
            <Text htmlTag='span'
              textStyle='body-3'
              color='neutral-600'
              ariaHidden={ true }
            >
              { originalText }
            </Text>
          </span>
          <Text htmlTag='span'
            textStyle='body-3'
            color='neutral-600'
            textDecoration='line-through'
            ariaHidden={ true }
          >
            { listPrice }
          </Text>
        </>
      );
    }
    else {
      originalText = tokenizedString.substring( 2, tokenStringLength );
      return (
        <>
          <span className='ProductPricing__originalPrice'>
            <Text htmlTag='span'
              textStyle='body-3'
              color='neutral-600'
              textDecoration='line-through'
              ariaHidden={ true }
            >
              { listPrice }
            </Text>
          </span>
          <Text htmlTag='span'
            textStyle='body-3'
            color='neutral-600'
            ariaHidden={ true }
          >
            { originalText }
          </Text>
        </>
      );
    }
  };

  const priceLabel = ( label ) => {
    return (
      <Text htmlTag='p'
        textStyle='body-3'
        color='magenta-500'
      >
        { label }
      </Text>
    );
  };

  const appOnly = appDownloadLabel && appDownloadAction;
  return (
    <div className='ProductPricing'
      id={ id }
      aria-live='polite'
      aria-atomic='true'
    >
      { listPrice && !salePrice &&
      <Text htmlTag='span'
        textStyle={ compact ? 'body-2' : 'title-5' }
        color='black'
      >
        { listPrice }
      </Text>
      }
      { listPrice && salePrice &&
      <>
        <span className='sr-only'>{ interpolateString( salePriceAccessibilityLabel, [salePrice] ) }</span>
        <Text htmlTag='span'
          textStyle={ compact ? 'body-2' : 'title-5' }
          color='magenta-500'
          ariaHidden={ true }
        >
          { salePrice }
        </Text>
        <span className='sr-only'>{ interpolateString( listPriceAccessibilityLabel, [listPrice] ) }</span>
        { setOriginalPrice( { tokenizedString: originalPriceLabel, listPrice: listPrice } ) }
      </>
      }
      { kitPrice &&
      <>
        { /* TODO : screen reader only label will be localized */ }
        <span className='sr-only'>{ `Kit Price ${ kitPrice }` }</span>
        <Text htmlTag='span'
          textStyle='body-3'
          color='neutral-600'
          ariaHidden={ true }
        >
          { kitPrice }
        </Text>
      </>
      }
      { !listPrice &&
      <Text htmlTag='span'
        textStyle='body-3'
        color='neutral-600'
      >
        { message }
      </Text>
      }
      { props.priceLabel &&
      <>
        { priceLabel( props.priceLabel ) }
      </>
      }
      { inflection.MOBILE && appOnly &&
      <div className='ProductPricing__appOnly'>
        <Icon
          className={ classNames( 'ProductPricing__appOnly--walletIcon' ) }
          aria-hidden={ true }
          alt='wallet'
          name='Wallet'
          size='s'
        />
        <Text htmlTag='span'
          textStyle='body-3'
          color='neutral-600'
        >
          { appDownloadLabel }
        </Text>
        <Link_Huge compact
          action={ appDownloadAction }
          className={ 'ProductPricing__appOnly--downloadTheApp' }
        >
          <Text
            htmlTag='span'
            textStyle='body-3'
          >
            { appDownloadAction.label }
          </Text>
        </Link_Huge>
      </div>
      }
    </div>
  );
};

/**
  * property type definitions
  * @typedef ProductPricingProps
  * @type {object}
  * @property {string} listPrice - Sets the list price to display
  * @property {string} originalPriceLabel - Sets the original price text to display with list price
  * @property {string} listPriceAccessibilityLabel - Sets the list price accessibility label for screen readers
  * @property {string} salePrice - Sets the sale price to display
  * @property {string} salePriceAccessibilityLabel - Sets the sale price accessibility label for screen readers
  * @property {string} kitPrice - Sets the kit value to display
  * @property {boolean} compact - Flag to render the compact size
  * @property {boolean} xLarge - Flag to render the xLarge size
  * @property {string} message - Sets the message to display
  * @property {string} priceLabel - Sets the price label to display
  * @property {boolean} realTimePricing - Flag to set the skip of nonchached call
  * @property {string} appDownloadLabel - Sets the app download label to display
  * @property {object} appDownloadAction - Sets the app download action
  * @property {string} id - Sets the id of the main div
  */
export const propTypes = {

  /** Sets the list price to display */
  listPrice: PropTypes.string,
  /** Sets the original price text to display with list price */
  originalPriceLabel: PropTypes.string,
  /** Sets the list price accessibility label for screen readers */
  listPriceAccessibilityLabel: PropTypes.string,
  /** Sets the sale price to display */
  salePrice: PropTypes.string,
  /** Sets the sale price accessibility label for screen readers */
  salePriceAccessibilityLabel: PropTypes.string,
  /** Sets the kit value to display */
  kitPrice: PropTypes.string,
  /** Flag to render the compact size */
  compact: PropTypes.bool,
  /** Flag to render the xLarge size */
  xLarge: PropTypes.bool,
  /** Sets the message to display */
  message: PropTypes.string,
  /** Sets the price label to display */
  priceLabel: PropTypes.string,
  /** Flag to set the skip of nonchached call */
  realTimePricing: PropTypes.bool,
  /** Sets the app download label to display */
  appDownloadLabel: PropTypes.string,
  /** Sets the app download action */
  appDownloadAction: PropTypes.object,
  /** Sets the id of the main div */
  id: PropTypes.string
};

/**
  * Default values for passed properties
  * @type {object}
  * @property {string} priceLabel='' - The translated price label to display
  */
export const defaultProps = {
  priceLabel: ''
};

ProductPricing.propTypes = propTypes;
ProductPricing.displayName = 'ProductPricing';
ProductPricing.defaultProps = defaultProps;

export default ProductPricing;