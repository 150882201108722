/**
 * The I18nProvider component is used to provide localized content to views via DXL commands that are relative to the locale that is passed into the query.
 *
 * @module core/providers/I18nProvider/I18nProvider
 */
import React, { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { Apollo_Client } from '@ulta/core/utils/apollo_client/apollo_client';

/**
 * Publicly accesible Application locale string
 * @type string
 */
export let ApplicationLocale;

/**
 * Represents a I18nProvider component
 *
 * @method
 * @param { Object } props - React properties passed from composition
 * @returns I18nProvider
 */
export const I18nProvider = function( { children, locale } ){
  const appLocale = locale;
  // set the public value
  const [currentLocale, setLocale] = useState( appLocale );
  ApplicationLocale = currentLocale;
  return (
    <I18nContext.Provider value={ { locale: currentLocale, toggleLocale: toggleLocale( setLocale, Apollo_Client ) } } >
      { children }
    </I18nContext.Provider>
  );
};

/**
 * property type definitions
 * @type object
 */
export const propTypes = {
  locale: PropTypes.string
};

/*
* ToggleLocale is a curried function which allows us to pass the state 'setLocale', method to be called in
an external context of the functional component.
* @method
* @param { function } setLocale - A aliased function which is used to pass a react hooks setMethod into an external context
* @returns function
*/
export const toggleLocale = function( setLocale, apollo_client ){
  return function( locale ){
    setLocale( locale );
    ApplicationLocale = locale;
    // refretch active queries
    apollo_client.reFetchObservableQueries();
  };
};

I18nProvider.propTypes = propTypes;
export default I18nProvider;

/*
 * the default method hwich is passed into the I18n context provider
 * @method
 */
export const defaultToggleLocale = function(){};

/**
 * Default values the creation of the custon I18n context provider
 * @type object
 * @property { String } [ Locale='en-CA' ] - the applications locale
 * @property { function } [ toggleLocale=defaultToggleLocale ] - default method of tehe toggleLocale property
 */
export let initialState = {
  toggleLocale: defaultToggleLocale
};

export const I18nContext = createContext( initialState );

export const useLocaleContext = () => useContext( I18nContext );
