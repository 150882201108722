/**
 * Component should take one URL as a prop and against that URL it should generate QR Code Image
 *
 * @module views/components/QrCode
 * @memberof -Common
 */

import React from 'react';
import { QRCode as ReactQRCode } from 'react-qr-code';

import PropTypes from 'prop-types';

/**
 * Represents a QrCode component
 *
 * @method
 * @param {QrCodeProps} props - React properties passed from composition
 * @returns UltaQrCode
 */
export const UltaQrCode = function( props ){
  if( !props.url ){
    return null;
  }

  return (
    <div className='QrCode'>
      <ReactQRCode
        value={ props.url }
        size={ props.size }
        title='Q R Code'
        aria-label='Q R Code'
        role='img'
      />
    </div>
  );
};

/**
 * Property type definitions
 *
 * @typedef QrCodeProps
 * @type {object}
 * @property {string} url - url to generate qr code
 * @property {number} size - size of the qr code
 */
export const propTypes =  {
  url: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

/**
* Default values for passed properties
* @type {object}
* @property {number} size=105 - By default qrcode size is 105
*/
export const defaultProps = {
  size: 105
};

UltaQrCode.propTypes = propTypes;
UltaQrCode.defaultProps = defaultProps;

export default UltaQrCode;