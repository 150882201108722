/**
 * @typedef Action
 * @type {object}
 * @property {string} graphql GraphQL query
 * @property {string} clientActionType clientActionType
 * @property {string} navigationType navigationType
 */

/**
 * @const {object} DXL_NAVIGATION_TYPE - Navigation type string from DXL
 */
export const DXL_NAVIGATION_TYPE = {
  Flyout: 'flyout',
  Modal: 'modal',
  Redirect: 'redirect',
  Pop: 'pop',
  Push: 'push',
  Refresh: 'refresh',
  NewTab: 'newtab'
};
export const SDK_SESSION_UPDATE_EVENT_TYPES = {
  SESSION_CHANGE: 'session_change',
  LOGIN_STATUS: 'login_status',
  CART_ITEM: 'cart_item'
};

export const SDK_SUBSCRIPTION_EVENT = 'SDK_SUBSCRIPTION_EVENT';

/**
 * @const {object} CLIENT_ACTION_TYPES - Client action type string from DXL
 */
export const CLIENT_ACTION_TYPES = {
  GLADLY_CHAT_CLICK: 'gladly::chatnow::click',
  LEGACY_LOGIN: 'ulta::signin::click',
  MEDALLIA_FEEDBACK_CLICK: 'medallia::feedback::click',
  ONETRUST_CLICK: 'onetrust::donotsellpi::click',
  COPYTEXT_CLICK: 'ulta::copy::text',
  CONDITIONAL_VIEW_RENDER1: 'ulta::ConditinalViewRenderer::conditionalView1',
  CONDITIONAL_VIEW_DEFAULT: 'ulta::ConditinalViewRenderer::default',
  OVERLAY_DISMISS: 'ulta::overlay::dismiss'
};
