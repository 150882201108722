/**
 * The MiniProductCard component displays the products and helps the user with detailed information about the product.
 *
 * @module views/components/MiniProductCard
 * @memberof -Common
 */
import './MiniProductCard.scss';

import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Image from '@ulta/core/components/Image/Image';
import InlineMessage from '@ulta/core/components/InlineMessage/InlineMessage';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import useLoader from '@ulta/core/hooks/useLoader/useLoader';
import { hasItems } from '@ulta/core/utils/array/array';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import Tag from '@ulta/modules/Tag/Tag';

import ReviewStarsCard from '../ReviewStarsCard/ReviewStarsCard';

/**
 * handleButtonAction function for handling the click of the Button
 * @method
 * @param {Object} methods - Passing setstate methods as an argument
 */
export const handleButtonAction = ( methods ) => {
  const { showLoader, handleAction } = methods;
  showLoader();
  handleAction && handleAction();
};

/**
 * getMessageType function for getting InlineMessage messageType
 * @method
 * @param {Object} methods - Passing setstate methods as an argument
 */
export const getMessageType = ( data ) => {
  return (
    ( data?.color?.includes( '-' ) ? data.color.split( '-' )[0] : data?.color ) ||
    'warning'
  );
};

/**
  * Represents a MiniProductCard component
  *
  * @method
  * @param {MiniProductCardProps} props - React properties passed from composition
  * @returns MiniProductCard
  */
export const MiniProductCard = function( props ){
  const {
    action,
    actionProps,
    brandName,
    className,
    componentKey,
    handleAction,
    htmlTag,
    image,
    imageContainerClass,
    imageProps,
    loading,
    messages,
    primaryAction,
    productName,
    quantityLabel,
    quantity,
    salePrice,
    price,
    promoText,
    outOfStock,
    secondaryAction,
    size,
    variantImage,
    variantLabel,
    variantTypeLabel,
    additionalOffersText,
    reviewCount,
    rating,
    root,
    rootMargin,
    threshold,
    reviewAccessibilityLabel,
    adaRegionLabel
  } = props;
  const miniProductRef = useRef();

  useIntersectionObserver( miniProductRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );
  const [loader, showLoader, hideLoader] = useLoader( {} );

  useEffect( () => {
    !loading && hideLoader();
  }, [loading] );

  const productPrice = salePrice || price;

  const renderPriceQuantity = () => {
    if( !productPrice ){
      return null;
    }

    return (
      <div className='MiniProductCard__MainPrice'
        ref={ miniProductRef }
      >
        { loader }
        { productPrice &&
          <Text
            htmlTag='span'
            textStyle='body-2'
            color='black'
          >
            { productPrice }
          </Text>
        }
        { quantityLabel &&
          <Text
            htmlTag='span'
            textStyle='body-3'
            color='neutral-600'
          >
            { `${quantityLabel}: ${quantity}` }
          </Text>
        }
      </div>
    );
  };

  const productVariantType = () => {
    return (
      <>
        { variantTypeLabel &&
          <div className='MiniProductCard__Variants'>
            <Text
              htmlTag='span'
              textStyle='body-3'
              color='neutral-600'
            >
              { variantTypeLabel }
            </Text>

            { variantImage?.imageUrl && (
              <Image
                ariaHidden={ true }
                alt={ variantLabel }
                width={ 12 }
                height={ 12 }
                src={ variantImage.imageUrl }
              />
            ) }

            { !!variantLabel && (
              <Text
                htmlTag='span'
                textStyle='body-3'
                color='neutral-600'
              >
                { variantImage?.imageUrl ? variantLabel : `: ${variantLabel}` }
              </Text>
            ) }
          </div>
        }
      </>
    );
  };

  return (
    <div
      role='group'
      { ...( adaRegionLabel && { 'aria-label': adaRegionLabel } ) }
      className={ classNames( 'MiniProductCard', {
        [className]: className
      } ) }
    >
      { hasItems( messages ) &&
        <ul className='MiniProductCard__InlineMessageList'>
          { messages.map(
            ( msgObj, msgIdx ) =>
              msgObj?.message && (
                <li className='MiniProductCard__InlineMessage'
                  key={ `${componentKey}-mini-product-card-${msgIdx}` }
                >
                  <InlineMessage messageType={ getMessageType( msgObj.messageType ) }
                    message={ msgObj.message }
                  />
                </li>
              )
          ) }
        </ul>
      }
      <div className='MiniProductCard__MainContainer'>
        { image?.imageUrl &&
          <div
            className={ classNames( 'MiniProductCard__Image', {
              [`MiniProductCard__Image__${size}`]: size,
              [imageContainerClass]: imageContainerClass
            } ) }
          >
            <Image
              width={ size === 'small' ? 48 : 68 }
              height={ size === 'small' ? 48 : 68 }
              src={ image.imageUrl }
              { ...imageProps }
            />
          </div>
        }
        <div className='MiniProductCard__Details'>
          <div className='MiniProductCard__Content'>
            <div className='MiniProductCard__BrandName'>
              <Text
                textStyle='body-2'
                color='neutral-600'
                htmlTag='span'
              >
                { brandName }
              </Text>
            </div>
            <div className='MiniProductCard__ProductName'>
              <Text
                textStyle='body-2'
                color='black'
                htmlTag={ htmlTag }
              >
                { productName }
              </Text>
            </div>
            { productVariantType() }
            { !!reviewCount && !!rating &&
              <div className='MiniProductCard__rating'>
                <ReviewStarsCard
                  reviews={ reviewCount }
                  rating={ rating }
                  reviewAccessibilityLabel={ reviewAccessibilityLabel }
                  componentKey={ componentKey }
                />
              </div>
            }
            { renderPriceQuantity() }
            { promoText && !outOfStock &&
              <div className='MiniProductCard__offers'>
                <Tag
                  variant='value'
                  style='default'
                  message={ promoText }
                />
              </div>
            }
            { additionalOffersText && !outOfStock &&
              <div className='MiniProductCard__AdditionalOffersText'>
                <Tag
                  variant='value'
                  style='default'
                  message={ additionalOffersText }
                />
              </div>
            }
            { outOfStock &&
              <div className='MiniProductCard__outOfStock'>
                <Tag
                  variant='utilityCompact'
                  style='error'
                  message={ outOfStock.message }
                />
              </div>
            }
            { primaryAction &&
              <div className='MiniProductCard__primaryAction'>
                <Link_Huge
                  compact
                  target='_self'
                  action={ primaryAction }
                  url={ primaryAction.url }
                  title={ primaryAction.label }
                >
                  { primaryAction.label }
                </Link_Huge>
              </div>
            }

            { action &&
              <div className='MiniProductCard__ButtonActionContainer'>
                <Button
                  className='MiniProductCard__ButtonAction'
                  tertiary
                  tiny
                  withHover
                  ariaHiddenIcon={ true }
                  label={ action.label }
                  { ...( !action?.navigationType && { onClick: () => handleButtonAction( { handleAction, showLoader } ) } ) }
                  { ...( !!action?.navigationType && { action, alignment: 'right' } ) }
                  { ...actionProps }
                />
              </div>
            }
          </div>

          { secondaryAction &&
            <div className='MiniProductCard__secondaryAction'>
              <Icon
                className='MiniProductCard__Icon'
                size='s'
                name={ secondaryAction }
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

/**
 * Property type definitions
 *
 * @typedef MiniProductCardProps
 * @type {object}
 * @property {object} action - button action
 * @property {string} additionalOffersText - additional Offers Text
 * @property {object} actionProps - Button props for button action
 * @property {string} additionalOffersText - additional Offers Text
 * @property {string} brandName - brand name
 * @property {object} dataCapture - data capture
 * @property {string} productName - product name
 * @property {string} variantTypeLabel - variant type
 * @property {string} variantLabel - variant label
 * @property {string} quantityLabel - quantity label
 * @property {string} quantity - quantity
 * @property {string} salePrice - sale price
 * @property {string} price -  price
 * @property {object.<{label: string, url: string}> } primaryAction - link action
 * @property {string} secondaryAction - caret forward action
 * @property {string} size - size of image
 * @property {string} image - image url
 * @property {object} imageProps - Image props for Product image - helps override the default props passed
 * @property {string} variantImage - sets the image url for product
 * @property {string} className - classname for custom styling of outer div
 * @property {string} messages - inline message object
 * @property {func} handleAction - click handler for `action` Button
 * @property {number} rating - rating for review stars
 * @property {string} reviewAccessibilityLabel - accessibility label for review stars
 * @property {string} adaRegionLabel - accessibility label for region
 * @property {number} reviewCount - review count for review stars
 */
export const propTypes = {
  action: PropTypes.shape( {
    graphql: PropTypes.string,
    label: PropTypes.string
  } ),
  additionalOffersText: PropTypes.string,
  actionProps: PropTypes.object,
  handleAction: PropTypes.func,
  brandName: PropTypes.string.isRequired,
  dataCapture: PropTypes.object,
  productName: PropTypes.string.isRequired,
  variantTypeLabel: PropTypes.string,
  variantLabel: PropTypes.string,
  quantityLabel: PropTypes.string,
  quantity: PropTypes.string,
  salePrice: PropTypes.string,
  price: PropTypes.string,
  primaryAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } ),
  secondaryAction: PropTypes.string,
  size: PropTypes.string,
  image: PropTypes.object,
  imageProps: PropTypes.object,
  outOfStock: PropTypes.shape( {
    message: ''
  } ),
  variantImage: PropTypes.shape( {
    imageUrl: PropTypes.string
  } ),
  className: PropTypes.string,
  messages: PropTypes.arrayOf(
    PropTypes.shape( {
      color: PropTypes.string,
      icon: PropTypes.string,
      message: PropTypes.string
    } )
  ),
  rating: PropTypes.number,
  reviewAccessibilityLabel: PropTypes.string,
  adaRegionLabel: PropTypes.string,
  reviewCount: PropTypes.number
};

/**
  * Default values for passed properties
  *
  * @type {object}
  * @property {string} brandName='' - The default brandName
  */
export const defaultProps = {
  adaRegionLabel: 'product',
  brandName: '',
  productName: '',
  variantTypeLabel: '',
  variantLabel: '',
  quantityLabel: '',
  quantity: '',
  salePrice: '',
  price: '',
  primaryAction: null,
  secondaryAction: '',
  size: ''
};

MiniProductCard.propTypes = propTypes;
MiniProductCard.defaultProps = defaultProps;
export default MiniProductCard;