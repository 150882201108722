/**
 * this component will handle the media asset coming from amplience.
 *
 * @module views/components/UltaAsset
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import Image from '@ulta/core/components/Image/Image';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import VideoPlayer from '@ulta/core/components/VideoPlayer/VideoPlayer';
import { getVideoUrl, VIDEO_FORMATS } from '@ulta/core/utils/media/media';

/**
 * Represents a UltaAsset component
 *
 * @method
 * @param {UltaAssetProps} props - React properties passed from composition
 * @returns UltaAsset
 */
export const UltaAsset = function( props ){
  const imageHasLinkAction = !props.videoUrl && props.imageUrl && props.action?.url;

  const videoUrl = getVideoUrl( { videoProfiles: props.videoProfiles, format: VIDEO_FORMATS.VP9720P, defaultUrl: props.videoUrl } );

  return (
    <div className='UltaAsset'>
      {
        !props.videoUrl && props.imageUrl && !props.action?.url &&
        <Image
          metaData={ props.metaData }
          src={ props.imageUrl }
          width={ props.width }
          alt={ props.altText }
          aspectRatio={ props.aspectRatio }
          ariaHidden={ props.ariaHidden }
          isBackground={ props.isBackground }
          shouldUseIntersectionObserver={ props.shouldUseIntersectionObserver }
          bgAnimation={ props.bgAnimation }
        />
      }
      {
        imageHasLinkAction &&
        <Link_Huge
          target='_self'
          action={ props.action }
        >
          {
            // Adding sr-only (screen reader only text) wrapping a backround image in a link
            // creates an empty link for the screen reader
            props.isBackground && props.altText &&
              <span className='sr-only'>{ props.altText }</span>
          }
          <Image
            metaData={ props.metaData }
            src={ props.imageUrl }
            width={ props.width }
            alt={ props.altText }
            aspectRatio={ props.aspectRatio }
            ariaHidden={ props.ariaHidden }
            isBackground={ props.isBackground }
            shouldUseIntersectionObserver={ props.shouldUseIntersectionObserver }
          />
        </Link_Huge>
      }
      {
        props.videoUrl &&
        <VideoPlayer
          metaData={ props.metaData }
          videoUrl={ videoUrl }
          videoThumbnail={ props.imageUrl }
          alt={ props.altText }
          name={ props.name }
          damStaticDomainPath={ props.damStaticDomainPath }
          isCaptionsEnabled={ props.isCaptionsEnabled }
          isInlineVideo={ props.isInlineVideo }
          isAutoPlay={ props.isAutoPlay }
          videoProfiles={ props.videoProfiles }
        />
      }
    </div>
  );
};

/**
 * Property type definitions
 * @typedef UltaAssetProps
 * @type {object}
 * @property {string} altText - assets image alt text
 * @property {boolean} ariaHidden - sets whether the aria is hidden or not
 * @property {string} aspectRatio - assets image aspectRatio
 * @property {string} damStaticDomainpath - the path for the static attributes hosted on the DAM
 * @property {string} imageUrl - the url of the image
 * @property {boolean} isAutoPlay - a flag to trigger autoplay on inline videos
 * @property {boolean} isBackground - a flag to identify background images
 * @property {boolean} isCaptionsEnabled - a flag for denoting if closed caoptiosn should be setup for a video
 * @property {boolean} isInlineVideo - a flag to identify inline videos
 * @property {object} metaData - Image metaData which described height, width, and file type for the asset
 * @property {string} name - the name of the asset
 * @property {string} videoUrl - the url of the video asset
 * @property {string} width - the configurable width of the asset
 * @property {boolean} bgAnimation - a flag to set the background color as placeholder while the banner image is loading
 */
export const propTypes =  {
  altText: PropTypes.string,
  ariaHidden: PropTypes.bool,
  aspectRatio: PropTypes.string,
  damStaticDomainPath: PropTypes.string,
  imageUrl: PropTypes.string,
  isAutoPlay: PropTypes.bool,
  isBackground: PropTypes.bool,
  isCaptionsEnabled: PropTypes.bool,
  isInlineVideo: PropTypes.bool,
  metaData:PropTypes.shape( {
    aspectRation: PropTypes.string,
    format: PropTypes.string,
    height: PropTypes.oneOfType( [PropTypes.string, PropTypes.number] ),
    imageTemplate: PropTypes.string,
    isCaptionEnabled: PropTypes.string,
    mediaType: PropTypes.string,
    width: PropTypes.oneOfType( [PropTypes.string, PropTypes.number] )
  } ),
  name: PropTypes.string,
  videoUrl: PropTypes.string,
  width: PropTypes.oneOfType( [PropTypes.string, PropTypes.number] ),
  shouldUseIntersectionObserver: PropTypes.bool,
  bgAnimation: PropTypes.bool
};

/**
  * property type definitions
  * @type object
  * @property {boolean} [isBackground=false] - a flag to identify images that need be rendered a background image.
  */
export const defaultProps = {
  isBackground: false,
  shouldUseIntersectionObserver: true
};

UltaAsset.propTypes = propTypes;
UltaAsset.defaultProps = defaultProps;

export default UltaAsset;
