
/**
 * Returns an object of the query string parameters
 * The attribute name will be the query param and the value of that attribute
 * will be it's value
 *
 * @method
 * @param { Object } searc - the URI to be parsed for query parameters
 */
export const getUrlParams = search => {
  const result = {};
  const params = new URLSearchParams( search );
  for ( let param of params ){
    const [key, value] = param;
    result[key] = value;
  }
  return result;
}

/**
 * Returns a boolean to indicate if the hostname passed in is a staging environment
 *
 * @method
 * @param { Object } hostname - the hostname
 */
export const isStagingEnvironment = hostname => {
  return ( /staging.*\.ulta\.com/ ).test( hostname );
};


